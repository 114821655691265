<template>
  <div class="d-flex">
    <toolbar v-if="isProfileSelected" />
    <v-container class="align-start grey lighten-3 app-container">
      <header v-if="isProfileSelected" class="no-print">
        <side-menu ref="sideMenu"></side-menu>
      </header>

      <v-row justify="center" no-gutters>
        <v-col cols="12" class="mb-15">
          <router-view></router-view>
        </v-col>
      </v-row>

      <app-footer v-if="isProfileSelected" />
      <comments-dialog />
    </v-container>
  </div>
</template>

<script>
export default {
  name: "LayoutApp",
  components: {
    "side-menu": () => import("@/layouts/components/Menu"),
    toolbar: () => import("@/layouts/components/Toolbar"),
    "comments-dialog": () =>
      import("@/domains/therapy/shared/presentation/components/CommentsDialog"),
    "app-footer": () => import("@/layouts/components/Footer"),
  },
  data: () => ({
    drawer: true,
  }),
  computed: {
    isProfileSelected() {
      return this.$store.getters["login/activeRole"] !== null;
    },
  },

  methods: {
    toggleDrawer() {
      this.$refs.sideMenu.toggleDrawer();
    },
  },
};
</script>

<style scoped></style>
